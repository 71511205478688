import { ReactComponent as LeftArrowIcon } from 'assets/images/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'assets/images/right-arrow.svg';

import YesIcon from 'assets/images/yes-icon.svg';

import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';

import Logo from 'assets/images/logo.svg';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
  REACT_APP_APPPOINTMENT_LINK: bookAppointmentLink,
} = process.env;

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'Star Dental Institute',
  tokenName: 'stardentalinstituteSubscriberToken',
  globalClass: 'star-dental-institute-container',
  clientHeader,
  enableHeroButton: true,
  pricingType: 'standard-pricing',
  headerProps: {
    enable: false,
  },
  homeTemplateProps: {
    heroSection: {
      variant: 2,
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButtonProps: {
        to: '/login',
        color: 'primary',
      },
      background: '/images/home-images/home-banner-img.webp',
    },
    benefitsSection: {
      variant: 2,
      columns: 3,
      alignCard: 'left',
      alignTitle: 'left',
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      },
    },
    howItWorksSection: {
      variant: 1,
      heroImage: '/images/home-images/how-it-works.webp',
    },
    testimonialSection: {
      variant: 1,
      alignTitle: 'left',
      heroImage: '/images/home-images/testimonial.webp',
      sectionClass: 'section-container',
      messageType: 'h6',
      showDots: false,
      showArrows: true,
      arrowProps: {
        icons: {
          left: <LeftArrowIcon />,
          right: <RightArrowIcon />
        }
      }
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    }
  },
  checkoutSection: {},
  findProviderSection: {},
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    tabLabel: {
      annual: 'Billed Annually',
      annual_billed_monthly: 'Billed Monthly'
    },
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: "contained",
        highlightedVariant: "outlined",
      }
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
      title: 'Frequently asked questions',
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: true,
    bookAppointmentProps: {
      link: bookAppointmentLink,
      target: '_blank'
    },
  },
  savingsSummary: {
    enableFooterbar: true,
  },
  faqSection: {},
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  footer: {
    enable: false,
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme
};

export default config;
