import { palette } from './palette';
import background from 'assets/images/faq-banner-img.webp';

export const homeStyle = {
  '.drawer-wrapper': {
    '& .drawer-title-container, .drawer-close-button': {
      color: palette.text.highlight,
    },
  },
  '.popup-action-button': {
    color: palette.primary.contrastText,
  },

  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      maxWidth: 'unset',
      minHeight: `unset !important`,

      '& .logo-image': {
        height: 46,
      },

      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
        maxWidth: '60%',

        '@media(min-width: 900px) and (max-width: 1200px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },

        '& .hero-shaped__image': {
          width: '60vw',

          '@media(min-width: 900px) and (max-width: 1200px)': {
            width: '50vw',
          },
        },
      },
    },
  },

  '.accordion__collapsable-text': {
    '& .accordion__collapsable-text_anchor': {
      textDecoration: 'none',
    },
    '& .accordion__collapsable-text_code': {
      textDecoration: 'underline',
    },
    '& .accordion__collapsable-text_number': {
      textDecoration: 'underline',
    },
  },

  '.star-dental-institute-container': {
    '& .home-hero-title': {
      fontWeight: '500',
    },
    '& .highlight-text': {
      fontWeight: 'bold',
      color: palette.text.headerhighlight,
    },

    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      backgroundColor: palette.text.highlight,

      '& .hero-background': {
        objectFit: 'cover',
        '@media screen and (max-width: 900px)': {
          height: '340px',
        },
      },
      '& .hero-section': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '550px',
        '& .section-header': {
          '@media screen and (min-width: 768px)': {
            marginTop: '60px',
          },
        },
      },
      '& .home-hero-subtitle': {
        maxWidth: 510,
      },
      '& .image-wrapper': {
        '@media(max-width: 900px)': {
          backgroundImage: `linear-gradient(90deg, #FFFFFF 16%, #E9F3F6 0%)`,
          padding: '24px 48px 24px 16px',
        },
        '& .image': {
          boxShadow: 'none',
          height: 'auto',
        },
      },
    },

    /* Benefits - Styles */
    '& #definition-section': {
      '@media(max-width: 900px)': {
        paddingTop: 0,
      },

      '& .section-header': {
        '@media(min-width: 900px)': {
          paddingBottom: 50,
        },
      },

      '& .definition-service-description': {
        paddingBottom: 48,
        borderBottom: `1px solid ${palette.background.level2}`,
      },

      '& .definition-service-tile': {
        padding: (32, 32, 16),
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      background: palette.background.primary,

      '& .subscription-bg': {
        position: 'unset',
        height: 576,
        background: `url('./images/home-images/pricing-benefits.webp') no-repeat center`,
        backgroundSize: 'cover',
      },
      '& #pricing-benefit-section': {
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
          backgroundColor: palette.text.alternate,
          border: `1px solid ${palette.text.highlight}`,
        },

        '& .subscription-section': {
          '& .select-plan-button': {
            background: palette.text.highlight,
            color: palette.text.primary,
          },
          '& .section-header': {
            '& .react-multi-carousel-dot-list': {
              display: 'none',
            },
            '@media(min-width: 900px)': {
              margin: '0px auto 32px',
            },
          },
          '& .summary-text': {
            fontWeight: 'normal',
          },
          '& .summary-subtext': {
            margin: '0 auto',
          },
        },

        '& .planCard': {
          color: palette.text.primary,

          '& .MuiButton-root': {
            borderWidth: 2,
            color: palette.text.primary,
          },
        },
        '& .planCard:hover': {
          '& .MuiButton-root': {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
          },
        },
        '& .savings-summary-link': {
          color: palette.text.primary,
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      background: 'unset',

      '& .step-counter': {
        background: palette.text.alternate,
      },
      '& .section': {
        borderBottom: `2px solid ${palette.text.disabled}`,
      },
      '& .howitworks-wrapper': {
        '& .how-cover-image': {
          height: 'auto',
        },
      },
      '& .working-steps-container:not(:last-child)': {
        paddingBottom: 30,
        marginBottom: 0,
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      paddingBottom: 80,
      '& .section-alternate': {
        borderBottom: `1px solid ${palette.text.disabled}`,
      },

      '& .section-header__overline-wrapper': {
        color: palette.text.alternate,
      },

      '& .testimonial-title-wrapper': {
        '& .section-header__title': {
          maxWidth: 500,
        },
      },
      '& .testimonial-container': {
        flexDirection: 'column-reverse',

        '@media(min-width: 900px)': {
          flexDirection: 'row-reverse',
        },
      },
      '& .testimonial-content-wrapper': {
        marginTop: 10,
        '@media(min-width: 900px)': {
          marginTop: -200,
        },

        '& .react-multi-carousel-track': {
          paddingTop: 60,
          '@media(min-width: 900px)': {
            paddingTop: 200,
          },
        },
        '& .custom-arrow': {
          border: 0,
          backgroundColor: 'unset',
          top: 0,
          width: 40,
          height: 40,
        },
        '& .left-arrow': {
          right: 75,
          left: 'unset',
        },
        '& .testimonial-message': {
          color: palette.text.primary,
          fontFamily: 'OpenSauceSans',
          fontWeight: 400,
        },
      },
      '& .image-wrapper': {
        display: 'flex',
        justifyContent: 'center',

        '@media(min-width: 900px)': {
          justifyContent: 'left',
        },

        '& .image': {
          height: '100%',
          width: 'auto',

          '@media(min-width: 900px)': {
            height: 414,
          },
        },
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      paddingBottom: '80px',
      '& .hero-static-bg': {
        backgroundColor: 'unset',
      },
      '& .hero-static-section': {
        backgroundColor: palette.background.secondary,
        borderRadius: 32,

        '@media(max-width: 900px)': {
          margin: (0, 20),
        },

        '& .section-header__title': {
          color: palette.text.highlight,
          maxWidth: 600,
        },
      },
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .service-plan-table .savings-subheader': {
        backgroundColor: palette.background.main,
        fontWeight: 600,
      },
      '& .savings-contact-card': {
        backgroundColor: palette.background.main,
      },
      '& .savings-header': {
        color: palette.text.primary,
      },
      '& .footer-bar': {
        background: palette.background.level1,
      },
      '& .savings-topheader': {
        fontWeight: '500 !important',
      },
    },

    /* Static Page Hero Banners */
    '& .find-provider-section, & .plan-selection-section, & .checkout-hero-section, & .dashboard-hero-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section, & .savings-hero-section, & .faq-hero-section':
      {
        background: palette.background.primary,
      },
    '& .book-appointment-hero-section': {
      height: '100%',
      backgroundColor: palette.background.main,

      '& .MuiTypography-root': {
        color: palette.text.primary,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .provider-details': {
        border: `1px solid ${palette.text.border}`,
      },
      '& .planCard': {
        '&:hover': {
          color: palette.text.primary,
        },
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .home-static-hero-wrapper': {
        '& .hero-static-section': {
          '& .section-header__title, .section-header__subtitle': {
            color: palette.text.highlight,
          },
        },
        '& .hero-static-bg': {
          backgroundColor: palette.background.secondary,
        },
      },
      '& .plans-wrapper': {
        '& .highlight-button': {
          backgroundColor: palette.text.highlight,
        },
        '& .highlight-divider': {
          backgroundColor: palette.text.disabled,
        },
      },
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
    },

    /* FAQ - Styles */
    '& .faq-hero-section': {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      '& .hero-header-title, .hero-header-subtitle': {
        color: palette.text.primary,
        margin: '0',
        textAlign: 'left',
      },
      '& .header-container': {
        justifyContent: 'left',
        margin: '0',
      },
    },
    '& .faq-contact-wrapper': {
      background: palette.background.primary,
      '& .contact-img-wrapper': {
        width: '64px',
        maxWidth: '64px',
      },
    },
  },

  /* Footer Disclosure */
  '& .footer-wrapper, & .footer-wrapper a': {
    backgroundColor: `${palette.background.secondary} !important`,
    color: `${palette.text.highlight} !important` 
  },
};
