export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#40A8E4',
    contrastText: '#FFFFFF',
    light: '#E9F3F6',
  },
  secondary: {
    main: '#FFFFFF',
    contrastText: '#092028',
  },
  text: {
    primary: '#002855',
    primaryLight: '#4F5A6B',
    headerhighlight: '#3174B4',
    secondary: '#5A6D8E',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
    alternate: '#57D4FF'
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#D5E9F2',
    paper: '#FFF',
    default: '#FFF',
    footer: '#002855',
    level2: '#C9D5E0',
    level1: '#51B9F5',
    tableHead: '#152435',
    tableSubhead: '#E9F8FF',
    tableAlternate: '#F8F8F8',
    alternate: '#E3F1FD',
    success: '#DBEFDC',
    error: '#FFEAEA',
    primary: 'linear-gradient(to right, #43B9EE, #D5E9F2);',
    secondary: '#3E89BB',
    disclaimer: "#04212B"
  },
  verticalBar: {
    used: '#40A8E4',
    unused: '#d5e9f2',
    unlimited: '#C0C0C0',
  }
};
